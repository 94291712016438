import React, { useState, useEffect } from "react";
import { IoMoon, IoSunny } from "react-icons/io5";


function DarkModeButton({dark, setDark}) {
    useEffect(() => {
        // Update the class of the body based on the dark state
        document.body.classList.toggle("dark", dark);

        // Save the current state to local storage
        localStorage.setItem('isDarkMode', dark);

        // Dispatch a custom event whenever the class changes
        window.dispatchEvent(new Event('classChange'));
    }, [dark]); // Depend on dark state to re-run this effect


    const darkModeHandler = () => {
        setDark(!dark);
    };


    return (
        <div>
            <button onClick={darkModeHandler}>
                {dark ? <IoSunny size="24px" /> : <IoMoon size="24px" />}
            </button>
        </div>
    );
}

export default DarkModeButton;
