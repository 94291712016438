import React, {useState, useEffect} from 'react';
import Plot from 'react-plotly.js';


export const PlotComponent = React.memo(({ selectedMeasurements, female_data, male_data, user_data, dark, isMobile }) => {
    // For mobile
    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        let adjustedWidth, adjustedHeight;

        // Tailwind's sm breakpoint is 640px
        if (width <= 640) { // For small devices
            adjustedWidth = width;
            adjustedHeight = height * 0.6;
        }
        else if (width <= 1440) { // For medium devices
            adjustedWidth = width * 0.75;
            adjustedHeight = height * 0.85;
        }
        else { // For larger devices
            adjustedWidth = width * 0.78;
            adjustedHeight = height * 0.9;
        }

        return { adjustedWidth, adjustedHeight };
    };


    const { adjustedWidth, adjustedHeight } = getWindowDimensions();


    // Access the colors from the imported Tailwind config (not actually imported)
    const colors = {
        "discord-dark": "#303338",
        "discord-cotton-candy": "#f5f1fa",
        "discord-cotton-candy-secondary": "#fbc4cc",
        "warm_white": "#FAFAF5",
        "cream_white": "#FFF8E7"
    };


    const chartLayout = {
        paper_bgcolor: dark ? colors["discord-dark"] : colors["discord-cotton-candy"],
        plot_bgcolor: dark ? colors["discord-dark"] : colors["discord-cotton-candy"],
        font: {
            color: dark ? colors["cream_white"] : '#000000', // Text color for titles, labels, etc.
        },
        margin: {l: 0, r: 0, b: 0, t: 0},
        // autosize: true,
        width: adjustedWidth,
        height: adjustedHeight,
        legend: {
            itemdoubleclick: false, // Disables the ability to double-click on legend items
            orientation: isMobile ? 'h' : 'v', // Horizontal or vertical legend
        },
        scene: {
            xaxis: {
                title: selectedMeasurements.measurement1.name,
                gridcolor: dark ? colors["cream_white"] : '#444', // Grid color,
                spikecolor: dark ? colors["cream_white"] : '#444', // Spike color
            },
            yaxis: {
                title: selectedMeasurements.measurement2.name,
                gridcolor: dark ? colors["cream_white"] : '#444', // Grid color,
                spikecolor: dark ? colors["cream_white"] : '#444', // Spike color
            },
            zaxis: {
                title: selectedMeasurements.measurement3.name,
                gridcolor: dark ? colors["cream_white"] : '#444', // Grid color
                spikecolor: dark ? colors["cream_white"] : '#444', // Spike color
            },
            camera: {
                eye: {x: 1.45, y: 1.45, z: 1.45}, // Increased values to zoom out
                up: {x: 0, y: 0, z: 1}, // This sets the up direction (z-axis up by default)
                center: {x: 0, y: 0, z: 0} // This centers the camera on the plot
            }
        },
        uirevision: 'true', // Ensures that the layout does not reset on data updates
    };


    return (
        <Plot
            data={[
                // Female data series
                female_data && {
                    type: 'scatter3d',
                    mode: 'markers',
                    name: 'Female',
                    marker: {color: 'mediumvioletred', size: 5, opacity: 0.3},
                    x: female_data.map(item => item.x),
                    y: female_data.map(item => item.y),
                    z: female_data.map(item => item.z),
                    hovertemplate: `${selectedMeasurements.measurement1.name}: %{x}<br>${selectedMeasurements.measurement2.name}: %{y}<br>${selectedMeasurements.measurement3.name}: %{z}<extra></extra>`,
                },
                // Male data series
                male_data && {
                    type: 'scatter3d',
                    mode: 'markers',
                    name: 'Male',
                    marker: {color: 'blue', size: 5, opacity: 0.3},
                    x: male_data.map(item => item.x),
                    y: male_data.map(item => item.y),
                    z: male_data.map(item => item.z),
                    hovertemplate: `${selectedMeasurements.measurement1.name}: %{x}<br>${selectedMeasurements.measurement2.name}: %{y}<br>${selectedMeasurements.measurement3.name}: %{z}<extra></extra>`,
                },
                // Simulated border for "You" data series
                user_data && {
                    type: 'scatter3d',
                    mode: 'markers',
                    name: 'You Border', // Name it differently or use the same name but handle legend grouping
                    marker: {color: 'rgba(0, 0, 0, 0.5)', size: 12, opacity: 0.5}, // Adjust for desired border effect
                    x: user_data.x, // Assuming these are arrays
                    y: user_data.y,
                    z: user_data.z,
                    hoverinfo: 'skip', // Optionally skip hover info for border points,
                    showlegend: false, // Optionally hide from legend
                },
                // "You" data series (original)
                user_data && {
                    type: 'scatter3d',
                    mode: 'markers',
                    name: 'You',
                    marker: {color: 'firebrick', size: 10, opacity: 1},
                    x: user_data.x,
                    y: user_data.y,
                    z: user_data.z,
                    hovertemplate: `${selectedMeasurements.measurement1.name}: %{x}<br>${selectedMeasurements.measurement2.name}: %{y}<br>${selectedMeasurements.measurement3.name}: %{z}<extra></extra>`,
                },
            ]}
            layout={chartLayout}
            config={{
                displayModeBar: false,
            }}
        />
    )
});