import React from 'react';


const Footer = ({dark}) => {
    return (
        <div className="row">
            <a
                // href="https://discord.com/users/1052263609452994581"  // my discord user link
                href="https://discord.gg/YtnpYz84E3"  // trannerland invite
                className={"flex text-center italic text-xs justify-center items-center"}>
                <p>by robotbabe ♡</p>
                <img src={dark ? '/img/discord-dark.webp' : '/img/discord-cottoncandy.webp'} alt="discord" className="ml-2 border-1 border-black shadow-md rounded-full w-4 h-4"/>
            </a>
        </div>
    );
}

export default Footer;
