import React, {useEffect, useState, useCallback} from "react";
import {IoAlertCircleOutline} from "react-icons/io5";
import {get_measurement_names} from "../common/utils";
import measurement_tooltips from "../common/measurement_tooltips.json";


function MeasurementForm({selectedMeasurements, setSelectedMeasurements, dark, isMobile}) {
    const saved_dataset = localStorage.getItem('selectedDataset');

    const [selectedDataset, setSelectedDataset] = useState(saved_dataset ? saved_dataset : 'ansur_2');

    // Save selected dataset to local storage
    useEffect(() => {
        localStorage.setItem('selectedDataset', selectedDataset);
    }, [selectedDataset]);


    // Fetch the measurement names
    const [measurement_names, set_measurement_names] = useState([]);
    useEffect(() => {
        get_measurement_names(selectedDataset).then(data => {
            set_measurement_names(data);
        });
    }, [selectedDataset]); // Changed from measurement_names to selectedDataset


    const handleInputChange = (event, name) => {
        const {value} = event.target;
        // Send the raw string value to the debounced function
        setSelectedMeasurements(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                // Store the value as is (string) to preserve the input format
                value: value,
            }
        }));
    };


    // handleSelectChange remains unchanged
    const handleSelectChange = (event, name) => {
        setSelectedMeasurements(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name], name: event.target.value,
            }
        }));
    };

    const [tooltipText, setTooltipText] = useState({
        measurement1: '',
        measurement2: '',
        measurement3: '',
    });


    const handleHover = (clear, measurement) => {
        if (clear === '') {
            setTooltipText({
                measurement1: '',
                measurement2: '',
                measurement3: '',
            });
            return;
        }

        // get the measurement name from selectedMeasurements
        const measurement_name = selectedMeasurements[measurement].name;

        setTooltipText({
            ...tooltipText,
            [measurement]: measurement_tooltips[measurement_name] || 'No info available',
        });
    };

    return (
        <div>
            <div className={'flex mb-3 m-1 justify-center'}>
                <select
                    class={"mr-1 w-5/6 bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"}
                    value={selectedDataset} onChange={(event) => setSelectedDataset(event.target.value)}>
                    <option value="ansur_2">Ansur II</option>
                    <option value="europe_uni_students">European Students</option>
                </select>
            </div>
            <h1 className="text-center text-xs mb-2 italic dark:text-cream-white">All measurements are in metric.</h1>
            <div className={'flex mb-3 m-1'}>
                <select
                    class="mr-1 w-5/6 bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(event) => handleSelectChange(event, 'measurement3')}
                    value={selectedMeasurements.measurement3.name}>
                    {measurement_names.map((x, index) => (<option key={index} value={x}>
                        {x}
                    </option>))}
                </select>
                <input
                    class="w-1/6 bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="text"
                    value={selectedMeasurements.measurement3.value !== undefined ? selectedMeasurements.measurement3.value : ''}
                    onChange={(event) => handleInputChange(event, 'measurement3')}
                    placeholder="Your Measurement"
                />
                <div className="relative flex justify-center items-center">
                    <IoAlertCircleOutline
                        onMouseEnter={() => handleHover(false, 'measurement3')}
                        onMouseLeave={() => handleHover('')}
                        className={'cursor-pointer m-1 relative'}
                        size={isMobile ? '38px' : '24px'}
                        color={dark ? 'white' : 'black'}/>
                    {tooltipText.measurement3 && (
                        <div
                            className={`absolute bg-discord-cotton-candy-secondary dark:bg-gray-800 rounded-2xl py-2 px-4 left-1/2 top-full z-10 w-64 ${
                                isMobile ? '-translate-x-56' : '-translate-y-8 translate-x-5'
                            }`}>
                            {tooltipText.measurement3}
                        </div>
                    )}
                </div>
            </div>
            <div className={'flex mb-3 m-1'}>
                <select
                    class="mr-1 w-5/6 bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(event) => handleSelectChange(event, 'measurement2')}
                    value={selectedMeasurements.measurement2.name}>
                    {measurement_names.map((x, index) => (<option key={index} value={x}>
                        {x}
                    </option>))}
                </select>
                <input
                    class="w-1/6 bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="text"
                    value={selectedMeasurements.measurement2.value !== undefined ? selectedMeasurements.measurement2.value : ''}
                    onChange={(event) => handleInputChange(event, 'measurement2')}
                    placeholder="Your Measurement"
                />
                <div className="relative flex justify-center items-center">
                    <IoAlertCircleOutline
                        onMouseEnter={() => handleHover(false, 'measurement2')}
                        onMouseLeave={() => handleHover('')}
                        className={'cursor-pointer m-1 relative'}
                        size={isMobile ? '38px' : '24px'}
                        color={dark ? 'white' : 'black'}/>
                    {tooltipText.measurement2 && (
                        <div
                            className={`absolute bg-discord-cotton-candy-secondary dark:bg-gray-800 rounded-2xl py-2 px-4 left-1/2 top-full z-10 w-64 ${
                                isMobile ? '-translate-x-56' : '-translate-y-8 translate-x-5'
                            }`}>
                            {tooltipText.measurement2}
                        </div>
                    )}
                </div>
            </div>
            <div className={'flex mb-3 m-1'}>
                <select
                    class="mr-1 w-5/6 bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(event) => handleSelectChange(event, 'measurement1')}
                    value={selectedMeasurements.measurement1.name}>
                    {measurement_names.map((x, index) => (<option key={index} value={x}>
                        {x}
                    </option>))}
                </select>
                <input
                    class="w-1/6 bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="text"
                    value={selectedMeasurements.measurement1.value !== undefined ? selectedMeasurements.measurement1.value : ''}
                    onChange={(event) => handleInputChange(event, 'measurement1')}
                    placeholder="Your Measurement"
                />
                <div className="flex justify-center items-center relative">
                    <IoAlertCircleOutline
                        onMouseEnter={() => handleHover(false, 'measurement1')}
                        onMouseLeave={() => handleHover('')}
                        className={'cursor-pointer m-1 relative'}
                        size={isMobile ? '38px' : '24px'}
                        color={dark ? 'white' : 'black'}/>
                    {tooltipText.measurement1 && (
                        <div
                            className={`absolute bg-discord-cotton-candy-secondary dark:bg-gray-800 rounded-2xl py-2 px-4 left-1/2 top-full z-10 w-64 ${
                                isMobile ? '-translate-x-56' : '-translate-y-8 translate-x-5'
                            }`}>
                            {tooltipText.measurement1}
                        </div>
                    )}
                </div>
            </div>
        </div>);
}

export default MeasurementForm;