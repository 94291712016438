import Papa from "papaparse";


const get_measurement_names = async (dataset_name) => {
    // Load the data
    const data = await fetch(`./datasets/${dataset_name}.csv`)
        .then(response => response.text())
        .then(data => {
            return Papa.parse(data, { header: true }).data;
        });

    var names = Object.keys(data[0]);
    // Sort by name
    names.sort();
    return names;
};
export { get_measurement_names };


const get_dataset = async (dataset_name, selected_measurements) => {
    // Load the data
    const data = await fetch(`./datasets/${dataset_name}.csv`)
        .then(response => response.text())
        .then(data => {
            return Papa.parse(data, { header: true }).data;
        });


    // Separate by sex
    const female_data = data.filter(x => x.Sex === 'f').map(x => ({
        x: parseFloat(x[selected_measurements.measurement1.name]),
        y: parseFloat(x[selected_measurements.measurement2.name]),
        z: parseFloat(x[selected_measurements.measurement3.name])
    }));

    const male_data = data.filter(x => x.Sex === 'm').map(x => ({
        x: parseFloat(x[selected_measurements.measurement1.name]),
        y: parseFloat(x[selected_measurements.measurement2.name]),
        z: parseFloat(x[selected_measurements.measurement3.name])
    }));

    const user_data = {
        x: [selected_measurements.measurement1.value],
        y: [selected_measurements.measurement2.value],
        z: [selected_measurements.measurement3.value]
    };

    return {
        'female_data': female_data,
        'male_data': male_data,
        'user_data': user_data
    };
};
export default get_dataset;

