import React, {useState, useEffect} from 'react';
import {PlotComponent} from './Components/Plot';
import get_dataset from './common/utils.js';
import DarkModeButton from "./Components/DarkModeButton";
import MeasurementForm from "./Components/MeasurementForm";
import {Analytics} from "@vercel/analytics/react"
import Footer from "./Components/Footer";
import usePreventZoom from "./Components/PreventZoom";
import './index.css';


function App() {
    // Mobile
    usePreventZoom();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const { innerWidth: width } = window;
            setIsMobile(width <= 1000); // Adjust this threshold as needed
            console.log("Window width: ", width, "Is mobile? ", width <= 1000);
        };

        // Initial check plus setup of event listener
        handleResize();
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // No dependencies, runs once on mount


    // Dark mode
    const [dark, setDark] = useState(() => {
        const isDark = localStorage.getItem('isDarkMode');
        return isDark === 'true'; // Convert string to boolean
    });

    useEffect(() => {
        // Dynamically set CSS variables for animation based on the theme
        document.documentElement.style.setProperty('--start-color', dark ? '#ffffff' : '#000000');
        document.documentElement.style.setProperty('--end-color', dark ? '#000000' : '#ffffff');

        // Trigger reflow to restart the animation
        const el = document.body;
        void el.offsetWidth;

        // Apply the animation class to the body
        document.body.classList.add('background-fade-in-animation');

        // Cleanup: Remove the animation class after it's done
        const animationDuration = 500; // Match your CSS animation duration
        setTimeout(() => {
            document.body.classList.remove('background-fade-in-animation');
        }, animationDuration);
    }, [dark]);


    // Measurement input
    var savedMeasurements = JSON.parse(localStorage.getItem('selectedMeasurements'));

    // User input
    const [selectedMeasurements, setSelectedMeasurements] = useState(savedMeasurements || {
        measurement3: {
            name: 'Height',
            value: 173,
        },
        measurement2: {
            name: 'SHR (Bideltoid Breadth)',
            value: 1.18,
        },
        measurement1: {
            name: 'WHR (Breadth)',
            value: 0.71,
        },
    });


    // Dataset data
    const [female_data, set_female_data] = useState(null);
    const [male_data, set_male_data] = useState(null);
    const [user_data, set_user_data] = useState(null);

    useEffect(() => {
        get_dataset('ansur_2', selectedMeasurements).then(data => {
            set_female_data(data.female_data);
            set_male_data(data.male_data);
            set_user_data(data.user_data);
            // Load selected measurements from local storage
            localStorage.setItem('selectedMeasurements', JSON.stringify(selectedMeasurements));
        });
    }, [selectedMeasurements]);


    return (
        <div className={`w-screen flex flex-col ${dark ? 'dark:bg-discord-dark' : 'bg-discord-cotton-candy'} p-5 text-black dark:text-cream_white transition-colors duration-500`}>
            <Analytics id="GJY3XZ3Z3"/>
            <div className="flex items-center w-full justify-between">
                {/* invisible so the header centers */}
                <div className="flex-1 flex justify-start invisible">
                    <DarkModeButton dark={dark} setDark={setDark}/>
                </div>
                <div>
                    <h3 className="text-center text-3xl m-1 font-bold dark:text-cream_white">
                        bonepill.me
                    </h3>
                    <h1 className="text-center text-xs m-1 italic dark:text-cream_white">Warning: Bonepilling may cause
                        deep-seated depression and dysphoria.</h1>
                </div>
                <div className="flex-1 flex justify-end">
                    <DarkModeButton dark={dark} setDark={setDark}/>
                </div>
            </div>

            <div className={`flex ${isMobile ? 'flex-col' : ''} h-full w-full justify-center items-center`}>
                <MeasurementForm
                    className={'flex flex-col'}
                    selectedMeasurements={selectedMeasurements}
                    setSelectedMeasurements={setSelectedMeasurements}
                    dark={dark}
                    isMobile={isMobile}
                />
                {selectedMeasurements &&
                <PlotComponent
                    selectedMeasurements={selectedMeasurements}
                    female_data={female_data}
                    male_data={male_data}
                    user_data={user_data}
                    dark={dark}
                    isMobile={isMobile}
                />}
            </div>
            <Footer dark={dark}/>
        </div>
    );

}

export default App;
